<template>
  <div>
    <b-card no-body class="border mt-1">
      <b-card-header class="p-1">
        <div>
        <b-card-title class="mb-1">{{
          this.$t("curlang") == "en" ? "Top User" : "Top de Usuarios"
        }}</b-card-title>
           <b-card-sub-title>{{fechas.monday}} - {{fechas.sunday}}</b-card-sub-title>
        </div>
         <div class="d-flex align-items-center">
          <feather-icon icon="CalendarIcon" size="16" />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range' }"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            :placeholder="this.$t('curlang') == 'en' ?' Another Week':'Otra Semana'"
          />
        </div>
      </b-card-header>
      <b-table
        :items="items"
        :busy="busy"
        :fields="fields"
        responsive
        hover
        selectable
        select-mode="single"
      >
        <template #table-busy>
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BButton,
  BRow,
  BCol,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import SellerService from "@/services/SellerService";
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import 'moment-timezone';
const zone=Intl.DateTimeFormat().resolvedOptions().timeZone
export default {
  directives: { Ripple },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BButton,
    BRow,
    BCol,
    BCardBody,
    BBadge,
    BSpinner,
    flatPickr
  },
  data() {
    return {
      fields: [{label:'Username',key:'username'},{label:'DiscordId',key:'discordId'},
      {label:'Current Points',key:'currentPoints'},{label:'Points Gained',key:'poinGained'},
      {label:'Challenge Completed',key:'challengeCompleted'}],
      items: [],
      busy: true,
      lunes:null,
      lunesinsert:null,
      domingo:null,
      domingoinsert:null,
        rangePicker: [],
        fechas:{}
    }
  },
  mounted:async function() {
       await this.getTMSWeek()
    this.lunes=this.getLunes()
    this.domingo=this.getDomingo()
   this.lunesinsert=this.getLunesInsert()
   this.domingoinsert=this.getDomingoInsert()
  await this.getTops(this.fechas.monday,this.fechas.sunday)
  },
   watch:{
      rangePicker:function(v){
          var range=v?.split(' to ')
          if (range!=undefined && range.length>=2) {
               var dstart=moment(range[0]).tz(zone, true).toISOString(true)
            var dend=moment(range[1]).tz(zone, true).toISOString(true)
            this.getTops(range[0],range[1])
            this.lunes=range[0]
            this.domingo=range[1]
          }
      }
  },
  methods:{
     getTMSWeek:async function(){
      const d=(await SellerService.getTMSWeek(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.fechas=d
    },
    getTops:async function(l,d){
      this.busy=true
      this.items=[]
      const top=(await SellerService.getTopUser(JSON.parse(localStorage.getItem("auth")).discordId,l,d)).data
      this.items=top
         this.busy=false
    },
     getLunes:function() {
     var d = new Date();
      var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); 
        var ff= new Date(d.setDate(diff));
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
       return monthNames[ff.getMonth()]+' '+ff.getDate()+', '+ff.getFullYear()
    },
  getDomingo:function() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 6;
  const sunday = new Date(today.setDate(last));
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
   return monthNames[sunday.getMonth()]+' '+sunday.getDate()+', '+sunday.getFullYear()
    },
     getLunesInsert:function() {
     var d = new Date();
      var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); 
        var ff= new Date(d.setDate(diff));
        var range=ff.toISOString().split('T')
       return range[0]
    },
      getDomingoInsert:function() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 6;
  const sunday = new Date(today.setDate(last));
  var range=sunday.toISOString().split('T')
   return range[0]
    }

  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
